<template>
  <div>
    <b-card title="Rekap Izin Siswa" class="mb-3">
      <b-col md="12">
        <validation-observer ref="accountRules" tag="form">
          <b-form class="mt-1" @submit.prevent>
            <b-row>
              <b-col md="12" xl="6">
                <validation-provider #default="{ errors }" name="academic_year" rules="required">
                  <b-form-group label="Tahun Akademik" label-for="academic_year" :state="errors.length > 0 ? false : null"
                    label-cols-md="4">
                    <v-select id="academic_year" v-model="dataParent.academic_year"
                      :reduce="(academic_year) => academic_year.id" placeholder="Pilih Tahun Akademik"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="listTA" label="name" />
                    <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
                <validation-provider #default="{ errors }" name="semester" rules="required">
                  <b-form-group label="Semester" label-for="semester" :state="errors.length > 0 ? false : null"
                    label-cols-md="4">
                    <v-select id="semester" v-model="dataParent.semester" :reduce="(semester) => semester.value"
                      placeholder="Pilih Semester" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="listSemester" label="text" />
                    <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
                <b-form-group label="Periode" label-for="start_date" label-cols-md="4">
                  <validation-provider #default="{ errors }" name="start_date" rules="required">
                    <b-form-input id="start_date" v-model="dataParent.start_date" type="date"
                      :state="errors.length > 0 ? false : null" placeholder="Periode Awal" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

              </b-col>
              <b-col md="12" xl="6">
                <validation-provider #default="{ errors }" name="class_id" rules="required">
                  <b-form-group label="Kelas" label-for="class_id" :state="errors.length > 0 ? false : null"
                    label-cols-md="4">
                    <v-select id="class_id" v-model="dataParent.class_id" :reduce="(class_id) => class_id.id"
                      placeholder="Pilih Kelas" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="listClass"
                      label="class_desc" />
                    <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
                <validation-provider #default="{ errors }" name="student_id" rules="required">
                  <b-form-group label="Siswa" label-for="student_id" :state="errors.length > 0 ? false : null"
                    label-cols-md="4">
                    <v-select id="student_id" v-model="dataParent.student_id" :reduce="(student_id) => student_id.real_id"
                      placeholder="Pilih Siswa" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="listSiswa"
                      label="name" />
                    <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
                <validation-provider #default="{ errors }" name="type" rules="required">
                  <b-form-group label="Jenis Izin" label-for="type" :state="errors.length > 0 ? false : null"
                    label-cols-md="4">
                    <v-select id="type" v-model="dataParent.type" :reduce="(type) => type.id"
                      placeholder="Pilih Jenis Izin" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="listAbsent" label="absent_type_desc" />
                    <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>
            <hr>
            </hr>
            <b-row>
              <b-col cols="12" md="3">

                <b-form-group>
                  <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="mr-1" @click="GetAllData"
                    size="sm">
                    View
                  </b-button>
                  <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="success" class="mr-1" @click="ExportExcel"
                    size="sm">
                    Export
                  </b-button>
                </b-form-group>
              </b-col>

            </b-row>
          </b-form>
        </validation-observer>
      </b-col>
    </b-card>

    <b-card title="A. Data Siswa" class="mb-3">
      <hr>
      </hr>
      <b-row>
        <b-col cols="8">
          <b-row>
            <b-col cols="12" class="border-left">
              <b-form-group label="NISN" label-for="nisn" label-cols-md="4">
                <validation-provider #default="{ errors }" name="nisn" rules="required">
                  <b-form-input id="nisn" v-model="dataParent.nisn" type="text" :state="errors.length > 0 ? false : null"
                    placeholder="NISN" class="form-sm" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group label="Nama Lengkap" label-for="name" label-cols-md="4">
                <validation-provider #default="{ errors }" name="name" rules="required">
                  <b-form-input id="name" v-model="dataParent.name" type="text" :state="errors.length > 0 ? false : null"
                    placeholder="Nama" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group label="Jenis Kelamin" label-for="gender" label-cols-md="4">
                <validation-provider #default="{ errors }" name="gender" rules="required">
                  <b-form-input id="gender" v-model="dataParent.gender" type="text"
                    :state="errors.length > 0 ? false : null" placeholder="Jenis kelamin" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group label="Kelas" label-for="class" label-cols-md="4">
                <validation-provider #default="{ errors }" name="class" rules="required">
                  <b-form-input id="class" v-model="dataParent.class" type="text"
                    :state="errors.length > 0 ? false : null" placeholder="Kelas" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group label="Semester" label-for="semester" label-cols-md="4">
                <validation-provider #default="{ errors }" name="semester" rules="required">
                  <b-form-input id="semester" v-model="dataParent.semester" type="text"
                    :state="errors.length > 0 ? false : null" placeholder="Semester" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
        </b-col>

        <b-col cols="4" class="d-flex justify-content-between flex-column">
          <div class="d-flex justify-content-start">
            <b-avatar :src="`https://www.pngitem.com/pimgs/m/78-786293_1240-x-1240-0-avatar-profile-icon-png.png`"
              text="Devy Ferdiansyah, M. Kom" :variant="`light-primary`" size="208px" rounded />
          </div>
        </b-col>
      </b-row>
    </b-card>

    <b-card title="B. Data Izin" class="mb-3">
      <hr>
      </hr>
      <b-col md="12">
        <table class="table" id="recaptable">
          <thead>
            <tr>
              <th>No</th>
              <th>Tanggal</th>
              <th>Jenis Izin</th>
              <th>Keterangan</th>
              <th>Jam Mulai</th>
              <th>Jam Selesai</th>
              <th>Mata Pelajaran</th>
              <th>Guru Pengajar</th>
              <th>Guru Piket</th>
              <th>Attachment File</th>
            </tr>
          </thead>
          <tbody>
          </tbody>
        </table>
      </b-col>
    </b-card>

    <b-card title="Total Izin" class="mb-3">
      <hr>
      </hr>
      <b-col md="6">
        <b-form-group label="Sakit" label-for="sakit" label-cols-md="4">
          <validation-provider #default="{ errors }" name="sakit" rules="required">
            <b-form-input id="sakit" v-model="dataParent.sakit" type="number" :state="errors.length > 0 ? false : null"
              placeholder="Sakit" />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
        <b-form-group label="Terlambat" label-for="terlambat" label-cols-md="4">
          <validation-provider #default="{ errors }" name="terlambat" rules="required">
            <b-form-input id="terlambat" v-model="dataParent.terlambat" type="number"
              :state="errors.length > 0 ? false : null" placeholder="Terlambat" />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
        <b-form-group label="Meninggalkan Sekolah" label-for="alpha" label-cols-md="4">
          <validation-provider #default="{ errors }" name="alpha" rules="required">
            <b-form-input id="alpha" v-model="dataParent.alpha" type="number" :state="errors.length > 0 ? false : null"
              placeholder="Meninggalkan Sekolah" />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
    </b-card>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BAvatar,
  BButton,
  BCard,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BRow,
  BCol,
  BDropdown,
  BDropdownItem,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { VueGoodTable } from 'vue-good-table'
import Ripple from 'vue-ripple-directive'
import store from '@/store/index'
import { codeAdvance } from './code'

import 'jquery/dist/jquery.min.js';
import 'datatables.net';
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import "datatables.net-buttons/js/dataTables.buttons.js"
import "datatables.net-buttons/js/buttons.colVis.js"
import "datatables.net-buttons/js/buttons.flash.js"
import "datatables.net-buttons/js/buttons.html5.js"
import "datatables.net-buttons/js/buttons.print.js"

import axios from 'axios';
import $ from 'jquery';

import '@fortawesome/fontawesome-free/css/all.css'
import '@fortawesome/fontawesome-free/js/all.js'

export default {
  components: {
    vSelect,
    ValidationProvider,
    ValidationObserver,
    BCard,
    VueGoodTable,
    BDropdown,
    BDropdownItem,
    BAvatar,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BRow,
    BCol,
    BButton,
    // Table,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  mounted() {
    $('#datatable').DataTable({
      processing: true,
      responsive: true,
    });
  },
  data: function () {
    return {
      products: [],
    }
  },
  toEditForm(pesan) {
    alert(pesan);
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      dir: false,
      codeAdvance,
      searchTerm: '',
      modalRemove: '',
      dataParent: {
        date: this.NowDateFormat(),
        class_id: '',
        semester: '',
        start_date: '',
        end_date: '',
        type: '',
        subject_id: '',
      },
      DataProduct: [],
      listClass: [],
      listTA: [],
      listAbsent: [],
      listMP: [],
      listSiswa: [],
      listSemester: [
        { value: '1', text: '1' },
        { value: '2', text: '2' },
        { value: '3', text: '3' },
        { value: '4', text: '4' },
        { value: '5', text: '5' },
        { value: '6', text: '6' },
        { value: '7', text: '7' },
        { value: '8', text: '8' },
      ],
    }
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  created() {
    this.getClass()
    this.getTA()
    this.getAbsent()
    this.getMP()
    this.getsiswa()
  },
  methods: {
    NowDateFormat() {
      var NowDate = Date.now();
      var d = new Date(NowDate),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

      if (month.length < 2)
        month = '0' + month;
      if (day.length < 2)
        day = '0' + day;

      return [year, month, day].join('-');
    },
    formatDate(date) {
      var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

      if (month.length < 2)
        month = '0' + month;
      if (day.length < 2)
        day = '0' + day;

      return [year, month, day].join('-');
    },
    async getClass() {
      try {
        const response = await this.$http.get('/masterclasses')
        this.listClass = response.data.data
      } catch (err) {
        this.showToast('danger', 'top-center', 'Get Presences Not Success')
        console.log(err)
      }
    },
    async getTA() {
      try {
        const response = await this.$http.get('/academicyears')
        this.listTA = response.data.data
      } catch (err) {
        this.showToast('danger', 'top-center', 'Get Presences Not Success')
        console.log(err)
      }
    },
    async getAbsent() {
      try {
        const response = await this.$http.get('/absenttypes')
        this.listAbsent = response.data.data
      } catch (err) {
        this.showToast('danger', 'top-center', 'Get Presences Not Success')
        console.log(err)
      }
    },
    async getMP() {
      try {
        const response = await this.$http.get('/academicsubjects?status=all')
        this.listMP = response.data.data
      } catch (err) {
        this.showToast('danger', 'top-center', 'Get Presences Not Success')
        console.log(err)
      }
    },
    async getsiswa() {
      try {
        const response = await this.$http.get('/students')
        this.listSiswa = response.data.data
      } catch (err) {
        this.showToast('danger', 'top-center', 'Get Data Not Success')
        console.log(err)
      }
    },
    showToast(variant, position, text) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: 'Notification',
            icon: 'InfoIcon',
            text,
            variant,
          },
        },
        {
          position,
        },
      )
    },
    showMsgBoxTwo(id) {
      this.modalRemove = ''
      this.$bvModal
        .msgBoxConfirm('Please confirm that you want to delete Data.', {
          title: 'Please Confirm',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Yes',
          cancelTitle: 'No',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if (value) {
            this.removeData(id)
            this.modalRemove = value
          }
          this.modalRemove = value
        })
    },
    onRowClick(params) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: `Hello user! You have clicked on row ${params.row.id}`,
          icon: 'UserIcon',
          variant: 'success',
        },
      })
    },
    toRouteAddPegawai() {
      this.$router.push({ name: 'data-pegawai-add' })
    },
    toEditForm(pesan) {
      alert(pesan);
    },

    async ExportExcel() {
      var table = $('#recaptable').DataTable();
      table.page.len(-1).draw();
      window.open('data:application/vnd.ms-excel,' +
        encodeURIComponent($('#recaptable').parent().html()));
      setTimeout(function () {
        table.page.len(10).draw();
      }, 1000)
    },

    async GetAllData() {
      try {
        var StartDate = document.getElementById('start_date');
        var EndDate = document.getElementById('end_date');
        const Sd = StartDate.value.substring(0, 10);
        const Ed = EndDate.value.substring(0, 10);

        var academic_year = this.dataParent.academic_year;
        var semester = this.dataParent.semester;
        var academic_class_id = this.dataParent.academic_class_id;
        var academic_subject_id = this.dataParent.academic_subject_id;
        var absent_type = this.dataParent.absent_type;
        const response = await this.$http.get(`/absents?academic_year=${academic_year}&semester=${semester}&start_date=${Sd}&end_date=${Ed}&academic_class_id=${academic_class_id}&academic_subject_id=${academic_subject_id}&absent_type=${absent_type}`)
        var DataRespone = response.data;
        var number = 1;
        var RecapTemp = [];
        console.log(DataRespone);
        $.each(DataRespone, function (i, item) {
          var JsonData = {
            "no": number,
            "start_date": item.start_date.substr(0, 10),
            "end_date": item.end_date.substr(0, 10),
            "student_id": item.student_id,
            "nisn": item.nisn,
            "name": item.student_name,
            "gender": item.gender,
            "absent_type_desc": item.absent_type_desc,
            "description": item.description,
            "start_study_hour": item.start_study_hour,
            "end_study_hour": item.end_study_hour,
            "subject_descprition": item.subject_descprition,
            "guru_mk": item.guru_mk,
            "guru_piket": item.guru_piket,
            "vehicle": item.vehicle,
          }
          RecapTemp.push(JsonData);
          number++;
        });
        this.DataProduct = this.RecapTemp;
        $('#recaptable').DataTable().destroy();
        var myTable = $('#recaptable').DataTable({
          "paging": true,
          "lengthChange": true,
          "searching": true,
          "ordering": true,
          "info": true,
          "autoWidth": true,
          "data": this.JsonData,
          "columns": [{
            "title": "No",
            "data": "no"
          }, {
            "title": "Tanggal Pengajuan",
            "data": "date"
          }, {
            "title": "NISN",
            "data": "nisn"
          },
          {
            "title": "Nama Lengkap",
            "data": "name"
          },
          {
            "title": "L/P",
            "data": "gender"
          }, {
            "title": "Kelas",
            "data": "class_desc"
          }
            , {
            "title": "Mulai Tanggal",
            "data": "start_date"
          }
            , {
            "title": "Tanggal Akhir",
            "data": "end_date"
          }
            , {
            "title": "Jenis Izin",
            "data": "absent_type_desc"
          },
          {
            "title": "Review",
            "data": "null"
          }, {
            "title": "Review",
            "data": "null"
          }],
          columnDefs: [
            {
              targets: 9, // The index of the Actions column
              render: (data, type, row) => {
                console.log(row);
                return '<a class="btn btn-primary btn-sm" href="' + row.review + '" role="button">Review</a>';
              },
            },
            {
              targets: 10, // The index of the Actions column
              render: (data, type, row) => {
                console.log(row);
                return '<a class="btn btn-primary btn-sm" href="' + row.review + '" role="button">Review</a>';
              },
            },
          ],
        });
        myTable.clear();
        $.each(RecapTemp, function (index, value) {
          myTable.row.add(value);
        });
        myTable.draw();
      } catch (err) {
        this.showToast('danger', 'top-center', 'Get Data Not Success')
        console.log(err)
      }
    },
    handleClick(id) {
      // Your code here
      alert(id);
    },
    UpdateClick(id) {
      this.$router.push({ name: 'absensi-data-kehadiran-edit', params: { id: id } })
    },
    DeleteClick(id) {
      alert(id);
    },
    riseUpModalRemove(val) {
      this.showMsgBoxTwo(val)
    },
    async removeData(val) {
      try {
        await this.$http.delete(`/kehadirans/${val}`)
        await this.GetAllData()
        this.showToast('success', 'top-center', 'Delete Data Success')
      } catch (err) {
        this.showToast('danger', 'top-center', 'Delete Data Not Success')
        console.log(err)
      }
    },
  },
}
</script>

<style lang="scss" >
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
